import { Box, ButtonBase, Typography } from "@mui/material";
import { Secs2Minutes } from "utils/transformations";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import styles from "./index.module.css";
import { URL } from "variables";
import Image from "components/Image";
import emptyState from "assets/images/emptystate1.svg";
import { useHistory } from "react-router-dom";

const DocsList = props => {

    const {
        modules,
        course_id,
        setHideSideBar,

    } = props;
    const lesson_real = props.lesson;

    let isDocsEmpty = true;
    const history = useHistory();
    console.log(modules?.reduce((a, b) => {
        let sum_parcial = 0;
        b?.lessons.map((item, id) => {
            if (item.id == lesson_real.id) {
                sum_parcial += item?.files?.documents.length;
            }
        });
        return a + sum_parcial;
    }, 0))
    return (
        <Box>
            <Box>
                <div className="row">
                    <div className="col-12 mb-3">
                        <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', marginBottom: 5, fontWeight: 'bold', fontSize: 18 }}>Todos materiais complementares</p>
                        {modules?.reduce((a, b) => {
                            let sum_parcial = 0;
                            b?.lessons.map((item, id) => {
                                if (item.id == lesson_real.id) {
                                    sum_parcial += item?.files?.documents.length;
                                }
                            });
                            return a + sum_parcial;
                        }, 0) == 0 && <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', marginBottom: 5, fontWeight: 'normal', fontSize: 16, marginTop: 10 }}>Nenhum material nesta aula</p>}
                    </div>

                    {modules?.map(module => (
                        module?.lessons?.map(lesson => {
                            if (isDocsEmpty) isDocsEmpty = lesson?.files?.documents?.length == 0;
                            if (lesson.id == lesson_real.id) {
                                return lesson?.files?.documents?.map(doc => {
                                    const extension = doc?.path.split(".")[doc?.path.split(".").length - 1];
                                    return (
                                        <div key={Math.random()} className="col-12 col-md-6 col-lg-4">
                                            <div
                                                key={doc?.id}
                                                style={{ cursor: 'pointer' }}
                                                // className={styles.documentContainer}
                                                // style={{padding:'0 !important'}}
                                                onClick={() => {
                                                    // window.open(`${URL}lessons/files/download?fileId=${doc?.id}`)
                                                    history.push(`/courses/${course_id}/${doc?.id}`)
                                                    setHideSideBar(true)
                                                }}
                                            >
                                                <Typography >
                                                    <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', marginBottom: 5, fontWeight: 'bold', fontSize: 14 }}>{module.name + ' - ' + lesson.title}</p>
                                                </Typography>
                                                <Typography >
                                                    <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', marginBottom: 10 }} className="text_overflow_file">{doc?.name}.{extension}</p>
                                                </Typography>
                                                <Typography
                                                    style={{ marginTop: 5 }}
                                                // hidden={Math.trunc(doc?.duration) == 0}
                                                >
                                                    Tempo de leitura: {Math.trunc(doc?.duration) == 0 ? '-' : (Secs2Minutes(Math.trunc(doc?.duration)) + ' min(s)')}
                                                </Typography>
                                                <Box
                                                    style={{ marginTop: 5 }}
                                                    display="inline-flex"
                                                    justifyContent="space-between"
                                                    width="100%"
                                                >
                                                    <Box
                                                        display="flex"
                                                        flexDirection={"row"}
                                                    >
                                                        <PictureAsPdfIcon
                                                            style={{
                                                                color: "#b83535"
                                                            }}
                                                        />
                                                        <span>{extension}</span>
                                                    </Box>
                                                    <Typography>
                                                        {(doc.size_in_bytes / 1000000).toFixed(2)} MB
                                                    </Typography>
                                                </Box>
                                            </div>
                                            <hr />

                                        </div>
                                    );

                                })
                            }
                        })
                    ))}
                </div>

            </Box>
            {isDocsEmpty && (
                <Box
                    display="flex"
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Image
                        src={emptyState}
                        width={350}
                        height={350}
                        style={{
                            backgroundSize: "cover"
                        }}
                    />
                    <Typography
                        variant="h6"
                        align="center"
                    >
                        Sem arquivos para baixar
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

export default DocsList;