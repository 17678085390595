import { Box, ClickAwayListener, Popover, Typography } from "@mui/material";
import { createContext, useState } from "react";
import { motion } from "framer-motion";

import InfoContainer from "./InfoContainer";
import { STORAGE_URL } from "variables";
import { grey } from "@mui/material/colors";
import ExpandableModal from "./ExpandableModal";
import { useHistory } from "react-router-dom";
import Actions from "./InfoContainer/Actions";
import { useQuery, useQueryClient } from "react-query";
import ActionsHome from "./InfoContainer/Actions/ActionsHome";
import StarsRate from "components/StarsRate";
import { Get } from "utils/request";
import { useRef } from "react";
import { useEffect } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { removeHTML } from "utils/filters";

export const CardContext = createContext();

const Board = (props) => {
  const {
    id,
    image,
    course_thumbnail,
    name,
    description,
    modules,
    usercourse,
    rateAverage,
    evaluations,
    price,
    free,
    fontColor,
    listed_courses,
    ...others
  } = props;

  const [anchorEl, SetAnchorEl] = useState(null);
  const [modal, SetModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const card_desc_ref = useRef();
  const [show_desc, setShow_desc] = useState(false);
  const [not_in, setNot_in] = useState(false);
  const [expired, setExpired] = useState(false);

  const history = useHistory();

  const OpenModal = () => SetModal(true);
  const CloseModal = () => SetModal(false);

  const { isLoading, error, data } = useQuery(["course_light", id], () =>
    Get(`courses-main?id=${id}&filter_content=1`)
  );

  // const handleCardClick = () => {
  //   // if (Boolean(usercourse)) {
  //   //   // history.push(`/courses/home/${id}`);
  //   //   // jogar para player
  //   //   history.push(`/courses/${id}`);
  //   // }
  //   // else history.push(`/courses/adquire/${id}`);

  //   // jogar para player

  //   if (isLoading) return;
  //   if (data?.error != "NOT_IN") {
  //     history.push(`/courses/${id}`);
  //   } else history.push(`/courses/adquire/${id}`);
  // };

  const HandlePlayClick = (e) => {
    e.stopPropagation();
    if(data.status!=true){
      return;
    }
    if (free) {
      console.log("free");
      return history.push(`/courses/${id}`);
    }

    if (data?.error === "NOT_IN" || data?.error === "EXPIRED" ) {
      history.push(`/courses/adquire/${id}`);
    } else history.push(`/courses/${id}`);
  };

  useEffect(() => {
    if (data?.error == "NOT_IN" && free != true) {
      setNot_in(true);
    }
    else if (data?.error == "EXPIRED" && free != true) {
      setExpired(true);
    }
  }, [data]);

  const queryClient = useQueryClient();

  const onUpdate = async () =>
    await queryClient.refetchQueries("categories", { active: true });

  return (
    <Box
      id={"course_id_" + id}
      component={motion.div}
      // onHoverStart={e => SetAnchorEl(e.currentTarget)}
      // onMouseDown={OpenModal}
      // onTouchEnd={OpenModal}
      // boxShadow={"0 0 7px rgba(0, 0, 0, 0.7)"}
      onClick={HandlePlayClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        width: "28rem",
        maxWidth:'100%',
        overflow: "hidden",
        cursor: "pointer",
        position: "relative",
        transition: "ease 150ms, transform 350ms",
        padding: "0 0.5rem",
      }}
    >
      <ActionsHome
        onUpdate={onUpdate}
        id={id}
        isUserIn={props.isUserIn}
        SetAnchorEl={SetAnchorEl}
        not_in={not_in}
        expired={expired}
        {...props}
      />

      <div
        style={{
          height: "32rem",
          width: "100%",
          overflow: "hidden",
          borderRadius: "0.3rem",
          // backgroundColor: "black",
          position: "relative",
        }}
      >
        <div
          style={{
            backgroundImage: course_thumbnail ? `url(${STORAGE_URL}${course_thumbnail})` : `url(${STORAGE_URL}${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            transition: "ease 200ms",
            // transform: isHovered ? "scale(1.06)" : "scale(1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            opacity: isHovered ? "0.9" : "1",
            webkitMaskImage: 'linear-gradient(to top, transparent 5%, white 40%)',
            maskImage: 'linear-gradient(to top, transparent 5%, white 40%)',
          }}
        >
          <Box
            sx={{
              transition: "300ms ease",
              opacity: isHovered ? 1 : 0,
              background:
                "linear-gradient(280deg, rgba(121,94,180,0.8) 0%, rgba(55,133,191,0.8) 100%)",
              borderRadius: "10rem",
              display: "flex",
              alignItems: "center",
              color: "white",
              justifyContent: "center",
              gap: "0.2rem",
              padding: "0 0.5rem 0 0.2rem",
            }}
          >
            <PlayCircleOutlineIcon
              sx={{
                fontSize: "1.6rem",
              }}
            />
            <div style={{ fontSize: "1.2rem" }}>
              {not_in ? "Ver conteúdo" : (expired?'Expirado': "Assistir")}
            </div>
          </Box>
        </div>

        <div
          style={{
            padding: 10,
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 10,
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography style={{ marginBottom: "0.25rem", fontWeight: 'bold', color: fontColor }} variant={"h5"}>
            {removeHTML(name)}
          </Typography>

          {/* {description && (
            <div
              ref={card_desc_ref}
              style={{
                maxHeight: show_desc ? 190 : "1.6rem",
                overflow: "hidden",
                transition: show_desc ? "ease 500ms" : "ease 300ms 1s",
                position: "relative",
                textAlign: "justify",
              }}
              onMouseEnter={() => setShow_desc(true)}
              onMouseLeave={() => setShow_desc(false)}
            >
              {removeHTML(description)}{" "}
              <div
                style={{
                  background:
                    "linear-gradient(270deg, #f8f8f8 60%, rgba(255,255,255,0) 100%)",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  paddingLeft: "4rem",
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                }}
              >
                {show_desc ? "Clique para ver mais" : "Ver mais"}
              </div>
            </div>
          )} */}

          <div style={{ display: "flex" }}>
            <StarsRate rate={rateAverage} fontColor={fontColor} />
            <p style={{ marginLeft: 10, color: fontColor }}>
              {evaluations == null || evaluations.length == 0
                ? "Nenhuma avaliação"
                : evaluations.length == 1
                ? "1 avaliação"
                : evaluations.length + " avaliações"}
            </p>
          </div>
        </div>
      </div>

      <Popover
        disableScrollLock
        // hidden={true}
        onMouseDown={(e) => e.stopPropagation()}
        component={motion.div}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted={false}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        style={{ marginTop: 70, overflowY: "scroll" }}
      >
        <CardContext.Provider
          value={{
            OpenExpandableModal: OpenModal,
            CloseExpandableModal: CloseModal,
          }}
        >
          <ClickAwayListener onClickAway={() => SetAnchorEl(null)}>
            <Box
              component={motion.div}
              onHoverEnd={() => SetAnchorEl(null)}
              height={"fit-content"}
              width={450}
              // onClick={handleCardClick}
              // jogar para player
              onClick={HandlePlayClick}
            >
              <Box
                width={450}
                // bgcolor={grey[800]}
                boxShadow={"0 0 10px"}
                sx={{
                  aspectRatio: "16/9",
                  backgroundImage: `url(${STORAGE_URL}${image})`,
                  backgroundSize: "cover",
                }}
              />
              <InfoContainer {...props} />
            </Box>
          </ClickAwayListener>
        </CardContext.Provider>
      </Popover>
      <ExpandableModal
        modules={modules}
        image={image}
        name={name}
        description={description}
        open={modal}
        onClose={CloseModal}
      />
    </Box>
  );
};

export default Board;
