import { FormLabel, FormControl as Input } from "react-bootstrap";
import DefaultButton from "components/DefaultButton";
import QuestionsCard from "../../QuestionsCard";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import Label from "components/InfoLabel";
import { Box, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { Get } from "utils/request";
import { useDispatch } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Show } from "actions/SnackbarActions";
import { STORAGE_URL } from "variables";
import JoditEditor from "jodit-react";
import ColorInput from "components/Inputs/ColorInput";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { removeHTML } from "utils/filters";

const Section3 = ({
  show_section3,
  SetShowSection3,
  questions,
  SetQuestions,
  section3_title,
  SetSection3Title,

  section3_bgColor_1,
  SetSection3BgColor1,
  section3_bgColor_2,
  SetSection3BgColor2,

  JoditConfig,
}) => {
  const dispatch = useDispatch();
  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // load question data from another course
  const [courses_modal_questions, setCourses_modal_questions] = useState([]);

  const { data, isLoading, refetch } = useQuery(
    "courses",
    async () => await Get(`courses-main`),
    {
      onSuccess: (data) => {
        const { courses } = data || {};
        setCourses_modal_questions(courses);
      },
    }
  );

  const loadQuestionFromCourse = (loaded_questions) => {
    if (loaded_questions === null || loaded_questions?.length === 0) {
      dispatch(
        Show({
          severity: "error",
          show: true,
          message: "Não há questões para serem carregadas",
        })
      );
      return false;
    }

    let temp_questions = [];
    loaded_questions.map((question) => {
      temp_questions.push(JSON.parse(question));
    });
    SetQuestions([...questions, ...temp_questions]);

    dispatch(
      Show({
        show: true,
        message: "Questões carregadas com sucesso!",
      })
    );
  };

  // Questions
  const addQuestion = () => {
    SetQuestions([...questions, { question: "", answer: "" }]);
  };

  const removeQuestion = (index) => {
    let temp = [...questions];
    temp.splice(index, 1);

    SetQuestions(temp);
  };

  const handleQuestion = (index, question, answer) => {
    let temp = questions;
    temp[index].question = question;
    temp[index].answer = answer;
    SetQuestions([...temp]);
    console.log(questions);
  };

  const modal_style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "max-content",
  };

  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={show_section3}
              onChange={(e) => SetShowSection3(e.target.checked)}
            />
          }
          label="Ativar seção"
        />
      </FormGroup>
      <div
        style={{
          marginTop: "2rem",
          flexDirection: "column",
          display: "flex",
          gap: "1rem",
          marginTop: "2rem",
          position: "relative",
        }}
      >
        <div
          style={{
            display: show_section3 ? "none" : "flex",
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255,255,255,0.5)",
            zIndex: 10,
          }}
        >
          <div
            style={{
              margin: "auto",
              backgroundColor: "white",
              color: "red",
              padding: "1rem",
              borderRadius: "0.4rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.6rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
              }}
            >
              Seção desativada
            </Typography>
            <Typography
              variant="subtitle"
              style={{
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Por padrão, esta seção não sera exibida na página do curso. Clique
              em "Ativar seção" para ativá-la.
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={show_section3}
                    onChange={(e) => SetShowSection3(e.target.checked)}
                  />
                }
                sx={{ color: "black" }}
                label="Ativar seção"
              />
            </FormGroup>
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modal_style}>
            <div
              style={{
                backgroundColor: "#f5f5f5",
                padding: "4rem 6rem",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                borderRadius: "0.8rem",
                maxHeight: "calc(100vh - 6rem)",
                outline: "none",
              }}
            >
              <div
                style={{ position: "absolute", right: "0.6rem", top: "0.6rem" }}
              >
                <CloseRoundedIcon
                  sx={{ fontSize: "2rem", cursor: "pointer" }}
                  onClick={handleClose}
                />
              </div>

              <div>
                <Typography variant="h6" gutterBottom>
                  Escolha o curso que você deseja carregar as questões
                </Typography>

                <div
                  style={{
                    overflowY: "auto",
                    maxHeight: "40rem",
                    height: "100%",
                    padding: "2rem",
                    display: "grid",
                    gap: "1rem",
                  }}
                >
                  {courses_modal_questions?.map((course, index) => (
                    <div
                      key={index}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "",
                        width: "max-content",
                        margin: "0 auto",
                        padding: "0.6rem",
                        backgroundColor: "#dbdbdb",
                        borderRadius: "0.6rem",
                      }}
                      onClick={() => {
                        handleClose();
                        loadQuestionFromCourse(course.questions);
                      }}
                    >
                      <div
                        style={{
                          aspectRatio: "16/9",
                          width: "20rem",
                          overflow: "hidden",
                          backgroundImage: course.course_thumbnail ? `url(${STORAGE_URL}${course.course_thumbnail})` : `url(${STORAGE_URL}${course.image})`,
                          backgroundSize: "cover",
                        }}
                      >
                        {/* <img src={STORAGE_URL+course.image} alt="imagem do curso" style={{objectFit: 'cover'}} /> */}
                      </div>
                      <Typography variant="h6" sx={{ textAlign: "center" }}>
                        {removeHTML(course.name)}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
        {/* <Label
        label={
          <h4
            style={{
              display: "inline-block",
            }}
          >
            Seção 3
          </h4>
        }
        required
        title="Principais dúvidas frequentes com relação ao curso"
      /> */}
        <FormLabel>
          <Label
            label={
              <span style={{ fontSize: "1.2rem" }}>
                Cadastrar perguntas frequentes
              </span>
            }
            required
            title="Principais dúvidas frequentes com relação ao curso ou aos serviços prestados pela empresa."
          />
        </FormLabel>

        <FormLabel>
          Título da seção<span style={{ color: "red" }}>*</span>
          <JoditEditor
            config={JoditConfig}
            value={section3_title}
            onChange={(value) => SetSection3Title(value)}
          />
        </FormLabel>

        <FormLabel>
          <Label
            label={<span>Cor de fundo 1 </span>}
            required
            title="Esta cor será aplicada no fundo da seção."
          />
        </FormLabel>
        <ColorInput
          value={section3_bgColor_1}
          onChange={(color) =>
            color !== undefined && SetSection3BgColor1(color)
          }
        />

        <FormLabel>
          <Label
            label={<span>Cor de fundo 2 </span>}
            required
            title="Esta cor será aplicada no fundo da seção. Se for definida, um gradiente será aplicado baseado na cor 1."
          />
        </FormLabel>
        <ColorInput
          value={section3_bgColor_2}
          onChange={(color) =>
            color !== undefined && SetSection3BgColor2(color)
          }
        />

        <div
          style={{
            display: "flex",
            gap: "0.6rem",
            flexWrap: "wrap",
            marginTop: "1rem",
          }}
        >
          {questions.map((q, index) => (
            <QuestionsCard
              key={index}
              index={index}
              remove={removeQuestion}
              handle={handleQuestion}
              question={q.question}
              answer={q.answer}
            />
          ))}
          {questions.length === 0 && (
            <Typography
              variant="subtitle"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                padding: "1rem",
                borderRadius: "0.5rem",
              }}
            >
              Nenhum item adicionado
            </Typography>
          )}
        </div>

        <div style={{ display: "flex", gap: "1rem" }}>
          <DefaultButton
            bg="confirm"
            text="Adicionar opção"
            onClick={addQuestion}
            sx={{ marginTop: "1rem" }}
          />
          <DefaultButton
            bg="info"
            icon={<RestorePageIcon />}
            text="Carregar de outro curso"
            onClick={handleOpen}
            sx={{ marginTop: "1rem" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Section3;
