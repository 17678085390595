import { Container, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { moneyMask } from "utils/moneyMask";
import { STORAGE_URL } from "variables";
import Form from "../../Form";

const Pricing = ({ course, style }) => {
  const {
    description_image,

    image,
    free,

    cover_image,
    name,
    description,
    price,
    installments_quantity,
    installments_price,
    installments_details,

    section5_text1,
    section5_text2,
    section5_textColor,

    section5_bgColor_1,
    section5_bgColor_2,
    section5_accentColor,

    inDateRange,
  } = course;

  const [coverImageSrc, setCoverImageSrc] = useState("");
  const [descriptionImageSrc, setDescriptionImageSrc] = useState("");

  useEffect(() => {
    if (cover_image === undefined || cover_image === null) return;
    if (cover_image === "" || cover_image.length === 0) return;

    let reader = new FileReader();
    reader.onload = function (e) {
      setCoverImageSrc(e.target.result);
    };
    try {
      reader.readAsDataURL(cover_image);
    } catch (error) {
      setCoverImageSrc(`${STORAGE_URL}${cover_image}`);
    }
  }, [cover_image]);

  useEffect(() => {
    if (
      description_image === undefined ||
      description_image === null ||
      description_image === "null"
    )
      return setDescriptionImageSrc("");
    if (description_image === "" || description_image.length === 0)
      return setDescriptionImageSrc("");

    let reader = new FileReader();
    reader.onload = function (e) {
      setDescriptionImageSrc(e.target.result);
    };
    try {
      reader.readAsDataURL(description_image);
    } catch (error) {
      setDescriptionImageSrc(`${STORAGE_URL}${description_image}`);
    }
  }, [description_image]);

  return price ? (
    !free && (
      <section
        style={{
          padding: "6rem 0",
          background: `linear-gradient(180deg, ${section5_bgColor_1} 0%, ${section5_bgColor_2} 100%)`,
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          position: "relative",
        }}
      >
        {/* ghost image cover */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 1,
            opacity: "0.5",
            top: "0",
            overflow: "hidden",
          }}
        >
          <img className={style.ghostImage} src={coverImageSrc} />
        </div>
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "4rem",
            zIndex: 10,
            position: "relative",
          }}
        >
          {/* <Typography
            variant="h2"
            sx={{
              backdropFilter: "blur(1.4rem)",
              padding: "0.1rem 1rem",
              borderRadius: "0.8rem",
              color: section5_textColor,
            }}
            dangerouslySetInnerHTML={{ __html: name }}
          />
          <Typography
            variant="h5"
            sx={{
              backdropFilter: "blur(1.4rem)",
              padding: "0.1rem 1rem",
              color: section5_textColor,
            }}
            dangerouslySetInnerHTML={{ __html: description }}
          /> */}

{descriptionImageSrc !== "" && (
            <img
              src={descriptionImageSrc}
              alt="descrição"
              style={{
                objectFit: "contain",
                width: "90%",
                maxWidth:600
              }}
            />
          )}

          <div
            style={{ backdropFilter: "blur(1.4rem)", padding: "0.1rem 1rem" }}
          >
            <Typography
              variant="h5"
              sx={{ color: section5_textColor, textAlign: "center" }}
            >
              {section5_text1}{" "}
              {/* <span
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: section5_accentColor,
                }}
              >
                {installments_quantity}x
              </span>{" "}
              de{" "}
              <span
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: section5_accentColor,
                }}
              >
                {moneyMask(installments_price || '0,00')}
              </span> */}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: section5_textColor,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {section5_text2}{" "}
              {/* <span
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: section5_accentColor,
                }}
              >
                {moneyMask(price || '0,00')}
              </span> */}
            </Typography>
            {/* <Typography
              variant="body1"
              sx={{ textAlign: "center", color: section5_textColor, }}
              dangerouslySetInnerHTML={{ __html: installments_details }}
            /> */}
          </div>

          <div
            style={{
              backdropFilter: "blur(1.4rem)",
              display: "grid",
              gap: "1.4rem",
              padding: "1rem 2.2rem",
              backgroundColor: "rgb(0,0,0,0.1)",
              paddingBottom: "2rem",
              border: "0.16rem solid rgb(255,255,255,0.2)",
              borderRadius: "1rem",
            }}
            className={style.reflectionAnimation}
          >
            <Form
              image={course?.image}
              price={course?.price}
              inDateRange={course.inDateRange}
              color={section5_accentColor}
              {...course}
            />
          </div>
        </Container>
      </section>
    )
  ) : (
    <></>
  );
};

export default Pricing;
