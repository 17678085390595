import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ReactInputMask from "react-input-mask";
import CustomInput from "./CustomInput";
import CloseIcon from "@mui/icons-material/Close";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Post } from "utils/request";
import { Show } from "actions/SnackbarActions";

const modal_style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "0.4rem",
  maxWidth: "100%",
  minWidth: "36rem",
  height: "max-content",
  display: "flex",
  flexDirection: "column",
  padding: "2rem 3rem",
  // gap: "0.2rem",
};

const UserEditProfile = ({
  user_id,

  open_profile_edit,
  handleOpenEditProfile,
  handleCloseEditProfile,

  // user info
  name,
  SetName,
  email,
  SetEmail,
  cpf,
  SetCpf,
  birth_date,
  SetBirthDate,
  gender,
  SetGender,
  phone,
  SetPhone,
  photo,
  SetPhoto,

  // adress
  adress,
  SetAdress,
}) => {
  const formRef = useRef(null);
  const currentUser = useSelector((store) => store.AppReducer.user);
  const dispatch = useDispatch();

  const [emptyFields, setEmptyFields] = React.useState(false);
  const [invalidEmail, setInvalidEmail] = React.useState(false);


  const submitForm = async (e) => {
    e.preventDefault();
    // get all inputs from formRef
    const inputs = formRef.current.querySelectorAll("input");

    setEmptyFields(false);
    // check if any input is empty
    inputs.forEach((input) => {
      if (input.value === "") {
        setEmptyFields(true);
        dispatch(
          Show({
            show: true,
            severity: "error",
            message: "Preencha todos os campos",
          })
        );
        return;
      }
    });

    if (emptyFields) {
      return;
    }

    if (adress["zipCode"].value.length < 9) {
      dispatch(
        Show({
          show: true,
          severity: "error",
          message: "CEP inválido",
        })
      );
      return;
    } else if (phone.replace('-','').replace('(','').replace(')','').replace(/ /g,'').length < 11) {
      dispatch(
        Show({
          show: true,
          severity: "error",
          message: "Telefone inválido",
        })
      );
      return;
    } else if (cpf.replace('-','').replace(/\./g,'').length < 11) {
      dispatch(
        Show({
          show: true,
          severity: "error",
          message: "CPF inválido",
        })
      );
      return;
    } else if (birth_date.length < 10) {
      dispatch(
        Show({
          show: true,
          severity: "error",
          message: "Data de nascimento inválida",
        })
      );
      return;
    }

    const [day, month, year] = birth_date.split('/');

    if (new Date(new Date(+year, +month - 1, +day)) > new Date()) {
      dispatch(
        Show({
          show: true,
          severity: "error",
          message: "Data de nascimento inválida",
        })
      );
      return;
    }



    // check if email is valid
    if (!validateEmail(email)) {
      setInvalidEmail(true);
      return;
    } else {
      setInvalidEmail(false);
    }

    // save user info
    let form = new FormData();
    form.append("id", user_id);
    form.append("name", name);
    form.append("role_id", currentUser?.role_id);
    form.append("email", email);
    form.append("cpf", cpf);
    form.append("birth_date", birth_date);
    form.append("gender", gender);
    form.append("phone", phone);
    form.append("street", adress["street"].value);
    form.append("number", adress["number"].value);
    form.append("complement", adress["complement"].value);
    form.append("neighborhood", adress["neighborhood"].value);
    form.append("city", adress["city"].value);
    form.append("state", adress["state"].value);
    form.append("zipCode", adress["zipCode"].value);

    let response = await Post(`auth/edit_profile`, form);
    if (response.status === 200 || response.status === true) {
      dispatch(
        Show({
          show: true,
          severity: response?.severity,
          message: response?.message,
        })
      );
      handleCloseEditProfile();
      SetAdress({
        ...adress,
        ["hasAdress"]: true,
      })
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <div>
      <Modal
        open={open_profile_edit}
        onClose={handleCloseEditProfile}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal_style}>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
            component="form"
            ref={formRef}
          >
            <CloseIcon
              sx={{
                position: "absolute",
                top: "0.2rem",
                right: "-0.2rem",
                cursor: "pointer",
              }}
              onClick={handleCloseEditProfile}
            />

            <Typography variant="h5" style={{ textAlign: "center" }}>
              Editar informações do perfil
            </Typography>

            <Typography variant="h6" sx={{ marginTop: "1.2rem" }}>
              Informações pessoais
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.6rem",
                marginBottom: "0.6rem!important",
                justifyContent: "space-between",
                marginTop: "0.6rem",
              }}
            >
              <TextField
                label="Nome completo"
                size={"small"}
                sx={{ flexBasis: "8rem", flexGrow: "2" }}
                value={name}
                onChange={(e) => SetName(e.target.value)}
                error={emptyFields && name === ""}
                helperText={
                  emptyFields && name === "" ? "Campo obrigatório" : ""
                }
              />
              <FormControl sx={{ minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small">Sexo</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={gender}
                  label="Sexo"
                  onChange={(e) => {
                    SetGender(e.target.value);
                  }}
                  error={emptyFields && gender === ""}
                  helperText={
                    emptyFields && gender === "" ? "Campo obrigatório" : ""
                  }
                >
                  <MenuItem value="Feminino">Feminino</MenuItem>
                  <MenuItem value="Masculino">Masculino</MenuItem>
                  <MenuItem value="Outro">Outro</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.6rem",
                marginBottom: "0.6rem!important",
                justifyContent: "space-between",
              }}
            >
              <ReactInputMask
                mask={"99/99/9999"}
                value={birth_date}
                onChange={(e) => SetBirthDate(e.target.value)}
                disabled={false}
                maskChar=""
              >
                {() => (
                  <TextField
                    fullWidth
                    label="Data de nascimento"
                    size="small"
                    sx={{ flexBasis: "3rem", flexGrow: "1" }}
                    error={emptyFields && birth_date === ""}
                    helperText={
                      emptyFields && birth_date === ""
                        ? "Campo obrigatório"
                        : ""
                    }
                  />
                )}
              </ReactInputMask>
              <ReactInputMask
                mask={"999.999.999-99"}
                value={cpf}
                onChange={(e) => SetCpf(e.target.value)}
                disabled={false}
                maskChar=""
              >
                {() => (
                  <TextField
                    fullWidth
                    label="CPF"
                    size="small"
                    sx={{ flexBasis: "3rem", flexGrow: "1" }}
                    error={emptyFields && cpf === ""}
                    helperText={
                      emptyFields && cpf === "" ? "Campo obrigatório" : ""
                    }
                  />
                )}
              </ReactInputMask>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.6rem",
                marginBottom: "0.6rem!important",
                justifyContent: "space-between",
              }}
            >
              <ReactInputMask
                mask="+55 (99) 99999-9999"
                value={phone}
                onChange={(e) => SetPhone(e.target.value)}
                disabled={false}
                maskChar=""
              >
                {() => (
                  <TextField
                    fullWidth
                    label="Telefone"
                    size="small"
                    sx={{ flexBasis: "3rem", flexGrow: "1" }}
                    error={emptyFields && phone === ""}
                    helperText={
                      emptyFields && phone === "" ? "Campo obrigatório" : ""
                    }
                  />
                )}
              </ReactInputMask>
              <TextField
                label="E-mail"
                size={"small"}
                sx={{ flexBasis: "8rem", flexGrow: "1" }}
                value={email}
                onChange={(e) => SetEmail(e.target.value)}
                error={emptyFields && email === "" || invalidEmail}
                helperText={
                  emptyFields && email === "" ? "Campo obrigatório" : "" ||
                    invalidEmail ? "E-mail inválido" : ""
                }
              />
            </Box>

            <Typography variant="h6" sx={{ marginTop: "1.2rem" }}>
              Endereço
            </Typography>
            <Typography variant="body1" sx={{ marginTop: "0.6rem" }}>
              Insira o CEP para gerar os campos de endereço
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.6rem",
                marginBottom: "0.6rem!important",
                justifyContent: "space-between",
                marginTop: "0.6rem",
              }}
            >
              {/* CEP */}
              <CustomInput
                type="text"
                label="CEP"
                value={adress["zipCode"].value}
                maxLength="9"
                flexBasis="8rem"
                emptyFields={emptyFields}
                onChange={(e) => {
                  SetAdress({
                    ...adress,
                    zipCode: {
                      ...adress["zipCode"],
                      value: e.target.value
                        .replace(/\D/g, "")
                        .replace(/(\d{5})(\d)/, "$1-$2")
                        .replace(/(-\d{3})\d+?$/, "$1"),
                    },
                  });
                  if (e.target.value.length === 9) {
                    adress["zipCode"].handleChange(e.target.value);
                  }
                }}
              />
              {/* STREET */}
              <CustomInput
                label="Rua"
                value={adress["street"].value}
                flexBasis="28rem"
                emptyFields={emptyFields}
                onChange={(e) => {
                  SetAdress({
                    ...adress,
                    street: {
                      ...adress["street"],
                      value: e.target.value,
                    },
                  });
                }}
              />
              {/* NUMBER */}
              <CustomInput
                label="Número"
                type={"number"}
                value={adress["number"].value}
                flexBasis="6rem"
                emptyFields={emptyFields}
                onChange={(e) => {
                  SetAdress({
                    ...adress,
                    number: {
                      ...adress["number"],
                      value: e.target.value,
                    },
                  });
                }}
              />
              {/* neighborhood */}
              <CustomInput
                label="Bairro"
                value={adress["neighborhood"].value}
                flexBasis="20rem"
                emptyFields={emptyFields}
                onChange={(e) => {
                  SetAdress({
                    ...adress,
                    neighborhood: {
                      ...adress["neighborhood"],
                      value: e.target.value,
                    },
                  });
                }}
              />
              {/* city */}
              <CustomInput
                label="Cidade"
                disabled
                value={adress["city"].value}
                flexBasis="12rem"
                emptyFields={emptyFields}
                onChange={(e) => {
                  SetAdress({
                    ...adress,
                    city: {
                      ...adress["city"],
                      value: e.target.value,
                    },
                  });
                }}
              />
              {/* state */}
              <CustomInput
                label="Estado"
                disabled
                value={adress["state"].value}
                flexBasis="4rem"
                emptyFields={emptyFields}
                onChange={(e) => {
                  SetAdress({
                    ...adress,
                    state: {
                      ...adress["state"],
                      value: e.target.value,
                    },
                  });
                }}
              />
              {/* state */}
              {/* <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={adress["state"].fillOption}
                onChange={(e, value) => {
                  SetAdress({
                    ...adress,
                    ["state"]: { ...adress["state"], value: value },
                  });
                }}
                value={adress["state"].value ? adress["state"].value : null}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "0.6rem",
                    },
                  },
                  flexBasis: "4rem",
                  flexGrow: 1,
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Estado" />
                )}
              /> */}
            </Box>
            <Typography
              variant="body1"
              sx={{ color: "#5c5c5c", marginY: "1rem" }}
            >
              Os dados são de uso interno e não serão compartilhados com
              terceiros.
            </Typography>
          </Box>
          <button
            type="button"
            className="btn btn-primary"
            style={{ width: "max-content", marginTop: "1rem" }}
            onClick={submitForm}
          >
            Salvar alterações
          </button>
        </Box>
      </Modal>
    </div>
  );
};

export default UserEditProfile;
