import { Box, TableCell, TableRow, Typography, LinearProgress, Tooltip } from "@mui/material";

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { green, red } from "@mui/material/colors";
import { DownloadButton, RefreshButton } from "utils/Buttons";
import { API_URL } from "variables";
import useCourses from "../useCourses";
import { removeHTML } from "utils/filters";
import moment from "moment";
import DefaultButton from "components/DefaultButton";
import { BsArrowRight, BsFillEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const Board = (props) => {

    const {
       course,
       user

    } = props;

    return (
        <>
            <TableRow>
                <TableCell><p style={{marginBottom:0}}>{removeHTML(course.name)}</p></TableCell>
                <TableCell>
                   {course.groups.map((item,id2)=>(
                    <p style={{marginBottom:5}}>{item.name} ({moment(item.use_time_group==false?item.date_buy:item.sub_start).format('DD/MM/YYYY')} - {item.use_time_group==false?(item.availability_duration_days==-1?'': moment(item.date_buy).add('days',item.availability_duration_days).format('DD/MM/YYYY')):moment(item.sub_end).format('DD/MM/YYYY')}) {user?.role!='Aluno' && <Tooltip
                                title={'Ver Turma'}
                            >
                                 <Link to={`/groups/${item.id}/view`}><BsArrowRight/></Link></Tooltip>} </p>
                   ))}
                </TableCell>
                <TableCell>
                <DefaultButton
            to={user?.role=='Aluno'?`/courses/${course.id}`:`/courses/view/${course.id}`}
            width="2.2em"
            height="2.2em"
            padding={0}
            title={`Ver curso`}
            bg="warning"
            icon={<BsFillEyeFill size={17} color="white" />}
          />
                    {/* <DownloadButton
                        title={"Baixar certificado"}
                        href={`${API_URL}certificate/download?certificate_id=${certificateId}`}
                        component={"a"}
                        target={"_blank"}
                        disabled={isLoading}
                    /> */}
                    {/* <RefreshButton
                        title={"Gerar novo certificado"}
                        onClick={() => mutate(certificateId)}
                        disabled={isLoading}
                    /> */}
                </TableCell>
            </TableRow>
           
        </>
    );
}

export default Board;