import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from "@mui/icons-material/Close"
import DeleteIcon from '@mui/icons-material/Delete'
import { renderAlert, renderToast } from 'utils/Alerts'
import { Typography, IconButton, CircularProgress, TextField, MenuItem, } from "@mui/material"
import { useDispatch } from 'react-redux'
import { API_URL, URL } from 'variables'
import { CreateToken, Delete, Get, Post } from 'utils/request'
import { maskCard, maskCpf } from 'Auxiliar/Masks'
import { getSequenceYears } from 'utils/variables'
import { ToastContainer } from 'react-toastify'
import SweetAlert from 'react-bootstrap-sweetalert'
import DefaultButton from 'components/DefaultButton'

// Props can come from payment screen
const UserCards = ({ cardId, setCardId, billingAddress,color }) => {
  // -------------------------------------------------------------------
  //********************************************************************
  // -------------------------States------------------------------------
  const fillMonth = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  const fillYear = getSequenceYears()

  const [data, setData] = React.useState('')
  const [add, setAdd] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [toasted, setToasted] = React.useState(false)
  const [loadingSave, setLoadingSave] = React.useState(false)
  const [hasAddresses, setHasAddresses] = React.useState(false)
  const [idToDelete, setIdToDelete] = React.useState(null)

  const [cvv, setCvv] = React.useState('')
  const [name, setName] = React.useState('')
  const [brand, setBrand] = React.useState('')
  const [document, setDocument] = React.useState('')
  const [year, setYear] = React.useState('01')
  const [month, setMonth] = React.useState('2023')
  const [card, setCard] = React.useState({ value: '', mask: '', length: 16, cvv: 3 })
  const [errors, setErrors] = React.useState({
    card: { value: false, message: '' },
    month: { value: false, message: '' },
    year: { value: false, message: '' },
    document: { value: false, message: '' },
    name: { value: false, message: '' },
    cvv: { value: false, message: '' },
  })

  React.useEffect(() => {
    getData()
  }, [])
  const dispatch = useDispatch();
  // -----------------------------------------------------------------
  //******************************************************************
  // -------------------------Getting-data----------------------------
  const getData = async () => {
    setLoading(true)
    const response = await Get(`cards`)

    // If the request returns true status, an anonymous function is used to save the card number formated with firsts and last digits only
    if (response.status) {
      setData(response.cards)
      if(response.cards?.length>0){
        setCardId(response.cards[0].card_id)
      }
      setHasAddresses(response.has_addresses.length === 0 ? false : true)

      // If it returns false status, is generated the toast with error message
    }
    else if (response.message == 'Unauthenticated.') {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      dispatch({ type: "login", payload: { token: null, user: null } });
      return {};
    }
    else {
      renderToast({ type: 'error', msg: 'Erro ao buscar cartões, tente novamente mais tarde!' })
    }

    setLoading(false)
  }

  // -----------------------------------------------------------------
  //******************************************************************
  // -------------------------Saving-data-----------------------------
  const handleSave = async () => {
    const errors = getErrors()

    if (errors) {
      return
    }

    setLoadingSave(true)
    const { id, httpCode } = await CreateToken({
      cvv, exp_month: month, exp_year: year, holder_name: name, holder_document: document.replace(/\D/g, ''), number: card.value, brand: card.brand
    })

    if (httpCode !== 200) {
      renderToast({ type: 'error', msg: 'Erro ao salvar cartão, certifique-se que é um cartão válido!' })
      setLoadingSave(false);
      return
    }

    const response = await Post(`cards/create`, JSON.stringify({ cvv,token: id, billing_address: billingAddress }), { 'Content-Type': 'application/json' })
    console.log('response', response)
    // If it returns true status, is generated the toast with successful message and data is obtained against
    if (response.httpCode === 200) {
      renderToast({ type: 'success', msg: 'Cartão salvo com sucesso!' })
      // finally if there is no error, the function to get the data is called
      setAdd(false); clearFields(); getData()
    } else {
      renderToast({ type: 'error', msg: 'Erro ao salvar cartão, certifique-se que é um cartão válido!' })
    }

    setLoadingSave(false);
  }

  // -----------------------------------------------------------------
  //******************************************************************
  // -------------------------Deleting-data---------------------------
  const handleDelete = async (id) => {
    setLoading(true); setAdd(false); clearFields()
    const response = await Delete(`cards/delete/${id}`)

    // If it returns true status, is generated the toast with successful message and data is obtained against
    if (response) {
      setIdToDelete(null)
      getData()
    }
  }

  // -----------------------------------------------------------------
  //******************************************************************
  // -------------------------Other-functions-------------------------
  const handleCvvChange = (value) => {
    // The value is converted an array to be compared with the definied length, the value will only be saved if it is smaller
    if (!card.cvv && !toasted) {
      setErrors({ ...errors, cvv: { value: true, message: 'Digite um número de cartão' } })
      setToasted(true)
    }

    if (value.toString().length <= card.cvv) {
      resetErrors('cvv')
      setCvv(value.replace(/\D/g, ''))
    }
  }

  const resetErrors = (item) => {
    let newErrors = { ...errors }
    newErrors[item].value = false
    newErrors[item].message = ''

    setErrors(newErrors)
  }

  //Showing filds for adding a card
  const handleAdd = () => {
    if (hasAddresses) { setAdd(!add); clearFields() }
    else {
      renderAlert({
        title: `Dados incompletos`,
        text: `Por questões de segurança, preencha os dados de usuário e adicione algum endereço antes de prosseguir com o cadastro de cartão!`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
    }
  }

  React.useEffect(() => {
    if (toasted) setTimeout(() => setToasted(false), 3000)
  }, [toasted])

  //Cleaning up fields after remove them from the screen
  const clearFields = () => { setName(''); setCard(''); setBrand(''); setDocument(''); setMonth('01'); setYear('2023'); setCvv('') }

  const getErrors = () => {
    let newErrors = { ...errors }
    const items = { document, card: card.value, cvv, name };

    Object.entries(items).forEach(([key, item]) => {
      if (!item) {
        newErrors[key].value = true
        newErrors[key].message = 'Campo em branco'
      } else {
        newErrors[key].value = false
        newErrors[key].message = ''
      }
    })

    if (!card.value || card.value.length < 16) {
      newErrors.card.value = true
      newErrors.card.message = 'Cartão inválido'
    }
    if (!document || document.replace(/\D/g, '').length < 11) {
      newErrors.document.value = true
      newErrors.document.message = 'CPF inválido'
    }
    if (!cvv || cvv.length < card.cvv) {
      newErrors.cvv.value = true
      newErrors.cvv.message = 'CVV inválido'
    }

    setErrors(newErrors)

    return Object.values(newErrors).some(item => item.value)
  }


  return (
    // -------------------------Cards-Content-------------------------
    <div>
      <Typography variant={"h5"} sx={{ mb: 1, mt: 2 }} style={{marginBottom:5,color}}>
        <b>Cartões</b>
      </Typography>
      {!loading ?
        <div className="d-flex flex-wrap justify-content-md-start justify-content-center">
          {data.length > 0
            ? data.map((item, index) => (
              <div key={index} className="row payment-card mb-5 me-5 pointer" onClick={() => setCardId && setCardId(item.card_id)}>
                <div className="col-md-12 my-2 mt-4 bg-dark" style={{ height: '2rem' }}></div>

                <div className="d-flex">
                  <div style={{ width: 75, marginTop: 5 }}>
                    <img style={{ width: 75, height: 45 }} src={`${URL}brands/${item.brand.toLowerCase()}.png`} alt='brand' />
                  </div>
                  <div className="ms-auto">
                    {setCardId
                      ? <input type="radio" checked={cardId === item.card_id} />
                      : <IconButton onClick={() => setIdToDelete(item.card_id)}><CloseIcon sx={{ color: '#222' }} /></IconButton>
                    }
                  </div>
                </div>

                <div className="col-md-12 my-2">
                  <div className="d-flex flex-column" style={{color}}>
                    <p>{item.holder_name}</p>
                    <p>**** **** **** {item.last_four_digits}</p>
                    <div className="d-flex" style={{ fontSize: '.8rem' }}>
                      <span>{item.exp_month}/{item.exp_year}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))
            : <p style={{color}}>Sem cartões cadastrados</p>}
        </div> : <div className="d-flex justify-content-center p-5"><CircularProgress color='inherit' /></div>}

      {/* -------------------------Card-fields-section------------------------- */}
      <div className="row my-5">
        <div className="d-flex align-items-center">
          <Typography variant={"h5"} style={{color}}>
            <b>Adicionar Cartão</b>
          </Typography>
          <button onClick={handleAdd} className='rounded-button hvr-grow ms-2 d-flex align-items-center justify-content-center'>
            {add ? <DeleteIcon size={15} /> : <AddIcon size={20} />}
          </button>
        </div>
        {
          // -------------------------Name----------------------------------
          add &&
          <form className='anime-left mt-3' onSubmit={handleSave}>
            <div className="row align-items-end">
              <div className="col-md-6 my-2">
                <TextField
                  fullWidth
                  label='Nome do Títular'
                  style={{background:'white'}}

                  value={name}
                  onChange={({ target }) => { setName(target.value.replace(/\d+/g, '')); resetErrors('name') }}
                  error={errors.name.value}
                  helperText={errors.name.value && errors.name.message}
                />
              </div>
              {/* -------------------------Card-------------------------- */}
              <div className="col-md-6 my-2">
                <div className="input-group flex-nowrap">
                  <TextField
                    // fullWidth
                    label='Cartão do Títular'
                    value={card.mask}
                    style={{background:'white'}}

                    onChange={({ target }) => { setCard(() => maskCard(target.value)); setCvv(''); resetErrors('cvv'); resetErrors('card') }}
                    error={errors.card.value}
                    helperText={errors.card.value && errors.card.message}
                  />
                  <img className='brand' src={`${URL}brands/${card.brand ? card.brand.toLowerCase() : 'nocard'}.png`} alt='brand'></img>
                </div>
              </div>
            </div>
            {/* -------------------------Document------------------------ */}
            <div className='col-md-12 mt-4'>
              <TextField
                fullWidth
                label='CPF do Títular'
                id="document"
                value={document}
                style={{background:'white'}}

                onChange={({ target }) => { setDocument(() => maskCpf(target.value)); resetErrors('document') }}
                error={errors.document.value}
                helperText={errors.document.value && 'CPF Inválido'}
              />
            </div>
            {/* -------------------------Month--------------------------- */}
            <div className="row mt-4">
              <div className="col-md-3 my-2">
                <TextField
                  select
                  label="Mês"
                  defaultValue="01"
                  style={{background:'white'}}

                  fullWidth
                  onChange={({ target }) => setMonth(target.value)}
                  value={month}
                >
                  {fillMonth.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {/* -------------------------Year-------------------------- */}
              <div className="col-md-6 my-2">
                <TextField
                  select
                  label="Ano"
                  defaultValue={fillYear[0]}
                  style={{background:'white'}}

                  fullWidth
                  onChange={({ target }) => setYear(target.value)}
                  value={year}
                >
                  {fillYear.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {/* -------------------------CVV--------------------------- */}
              <div className="col-md-3 my-2">
                <TextField
                  fullWidth
                  label='CVV'
                  value={cvv}
                  style={{background:'white'}}

                  onChange={({ target }) => handleCvvChange(target.value)}
                  error={errors.cvv.value}
                  helperText={errors.cvv.value && errors.cvv.message}
                />
              </div>
            </div>

            {/* -------------------------Buttons-section------------------------- */}
            <div className="d-flex mt-5">
              <DefaultButton text='Salvar' loading={loadingSave} onClick={handleSave} />
            </div>
          </form>
        }
        {!add && <div className='anime-right mt-2'><Typography style={{color}}>Cadastre cartões para começar!</Typography></div>}
      </div >

      {idToDelete &&
        <SweetAlert
          showCancel
          focusConfirmBtn
          cancelBtnBsStyle="light"
          cancelBtnText="Cancelar"
          title={`Deletar cartão selecionado?`}
          onConfirm={() => handleDelete(idToDelete)}
          onCancel={() => setIdToDelete(null)}>
          <form>
            <Typography>{`Uma vez deletado, não dará para recuperá-lo!`}</Typography>
          </form>
        </SweetAlert>}

      <ToastContainer />
    </div >
  )
}

export default UserCards