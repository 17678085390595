import { FormLabel, FormControl as Input } from "react-bootstrap";
import VideoInput from "components/Inputs/VideoInput";
import ImageInput from "components/Inputs/ImageInput";
import { maskPrice } from "Auxiliar/Masks";
import Label from "components/InfoLabel";
import Responsibles from "../../Responsibles";
import Category from "../../Category";
import Tags from "../../Tags";
import JoditEditor from "jodit-react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DefaultButton from "components/DefaultButton";
import { MdModeEdit } from "react-icons/md";
import ColorInput from "components/Inputs/ColorInput";

const CheckoutCustomize = ({
  checkout_bg_color,
  SetCheckoutBgColor,
  checkout_accent_color,
  SetCheckoutAccentColor,
  checkout_card_color,
  SetCheckoutCardColor,
  checkout_font_color,
  SetCheckoutFontColor,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      
      <FormLabel>
        <Label
          label={<span>Cor de fundo</span>}
          required
          title="Esta cor será aplicada no fundo da seção."
        />
      </FormLabel>
      <ColorInput
        value={checkout_bg_color}
        onChange={(color) =>
          color !== undefined && SetCheckoutBgColor(color)
        }
      />

      <FormLabel>
        <Label
          label={<span>Cor de fundo do cartão </span>}
          required
          title="Esta cor será aplicada no fundo do cartão da seção."
        />
      </FormLabel>
      <ColorInput
        value={checkout_card_color}
        onChange={(color) =>
          color !== undefined && SetCheckoutCardColor(color)
        }
      />

      <FormLabel>
        <Label
          label={<span>Cor de destaque </span>}
          required
          title="Esta cor será aplicada em elementos de destaque da seção."
        />
      </FormLabel>
      <ColorInput
        value={checkout_accent_color}
        onChange={(color) =>
          color !== undefined && SetCheckoutAccentColor(color)
        }
      />

      <FormLabel>
        <Label
          label={<span>Cor do texto </span>}
          required
          title="Esta cor será aplicada aos textos dos cartões da seção."
        />
      </FormLabel>
      <ColorInput
        value={checkout_font_color}
        onChange={(color) =>
          color !== undefined && SetCheckoutFontColor(color)
        }
      />

    </div>
  );
};

export default CheckoutCustomize;
