import { useEffect } from "react";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { Post } from "utils/request";
import { API_URL } from "variables";

const useRoutesCoursesUsers = () => {

    const location = useLocation();

    const { mutate } = useMutation(async () => {
        return await Post(`${API_URL}certificate/generate`);
    });

    useEffect(() => {
        
    }, []);

    return {
        
    };
}

export default useRoutesCoursesUsers;