import React, { useEffect, useState } from "react";
import { Get } from "../../../../utils/request";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import Board from "../Card";
import SkeletonCardList from "modules/LoadingPlaceholders/Skeletons/SkeletonCardList";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

// mui icons
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { Container, Typography } from "@mui/material";

const DisplayCourses = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      breakpoints: {
        "(min-width: 0px)": {
          slides: { perView: 1 },
        },
        "(min-width: 940px)": {
          slides: { perView: 2 },
        },
        "(min-width: 1000px)": {
          slides: { perView: 2 },
        },
        "(min-width: 1380px)": {
          slides: { perView: 3 },
        },
        "(min-width: 1700px)": {
          slides: { perView: 4 },
        },
        "(min-width: 2200px)": {
          slides: { perView: 5 },
        },
      },
      slides: {
        origin: "center",
        // perView: 1,
        // spacing: 10,
      },
      loop: true,
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider?.next();
          }, 5000);
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on("dragStarted", clearNextTimeout);
        slider.on("animationEnded", nextTimeout);
        slider.on("updated", nextTimeout);
      },
    ]
  );
  const [mui_theme, setMuiTheme] = useState({});
  const [listed_courses, setListedCourses] = useState([]);
  const [categories, setCategories] = useState([]);

  const userId = useSelector((store) => store.AppReducer.user.id);
  const loginTheme = useSelector(
    (store) => store.StylesReducer.pagesThemes?.mural_cursos
  );

  useEffect(() => {
    setMuiTheme(loginTheme?.palette?.data);
  }, [loginTheme]);

  const [isLoading, setLoading] = useState(true);
  // const { data, error, refetch } = useQuery("categories", async () => {
  //   // setLoading(true);
  //   let data = await Get(`courses-main/category?userId=${userId}`);
  //   setLoading(false);

  //   return data;
  // });
  // const { categories } = data || {};

  // const listedCourses = useQuery("my-list", async () => {
  //   let data = await Get(`user-list-course?userId=${userId}`);
  //   setLoading(false);
  //   return data;
  // });

  const getCategories = async () => {
    await Get(`courses-main/category?userId=${userId}`).then((res) => {
      setCategories(res.categories);
      setLoading(false);
    });
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(()=>{
  instanceRef?.current?.update();

  },[categories])

  const getMyList = async () => {
    await Get(`user-list-course?userId=${userId}`).then((res) => {
      console.log(res);
      let temp_list = [];
      res?.list.map((course) => {
        temp_list.push(course.course_id);
      });
      setListedCourses(temp_list);
      console.log(temp_list);
    });
  };

  useEffect(() => {
    getMyList();
  }, []);

  if (isLoading)
    return (
      <Container maxWidth="xl" sx={{ padding: "0", paddingTop: "2rem" }}>
        <SkeletonCardList />
      </Container>
    );

  return (
    !isLoading && (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          backgroundColor: mui_theme.background_color,
          minHeight: "100vh",
          paddingTop: "2rem",
        }}
      >
        {categories?.map((category, index) => {
          return (
            category?.courses?.length !== 0 && (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0.8rem",
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    display: "flex",
                    gap: "0.2rem",
                    alignItems: "center",
                    fontWeight: "bold",
                    paddingLeft: "2rem",
                    color: mui_theme.font_color,
                  }}
                >
                  {category.name}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    // justifyContent: "space-between",
                  }}
                >
                  <div
                    ref={sliderRef}
                    className="keen-slider"
                    style={{
                      overflowY: "visible",
                      padding: "1.4rem 0",
                    }}
                  >
                    {category?.courses?.map((course, index2) => {
                      if (course?.lessons?.length !== 0)
                        return (
                          <div
                            className="keen-slider__slide"
                            style={{
                              overflow: "visible",
                            }}
                            key={index2}
                          >
                            <Board
                              {...course}
                              isUserIn={course?.usercourse !== null}
                              // GetData={refetch}
                              fontColor={mui_theme.font_color}
                              listed_courses={listed_courses}
                            />
                          </div>
                        );
                    })}
                  </div>
                  {loaded && instanceRef.current && (
                    <>
                      <div
                        style={{
                          backgroundColor: "rgba(255, 255,255 ,0.5)",
                          borderRadius: "50%",
                          padding: "1rem",
                          position: "absolute",
                          display: "flex",
                          justifyContent: "space-between",
                          top: "45%",
                        }}
                      >
                        <Arrow
                          left
                          onClick={(e) =>
                            e.stopPropagation() || instanceRef.current?.prev()
                          }
                          disabled={currentSlide === 0}
                        />
                      </div>
                      <div
                        style={{
                          backgroundColor: "rgba(255, 255,255 ,0.5)",
                          borderRadius: "50%",
                          padding: "1rem",
                          position: "absolute",
                          display: "flex",
                          justifyContent: "space-between",
                          top: "45%",
                          right: "0",
                        }}
                      >
                        <Arrow
                          onClick={(e) =>
                            e.stopPropagation() || instanceRef.current?.next()
                          }
                          disabled={
                            currentSlide ===
                            instanceRef.current.track.details.slides.length - 1
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            )
          );
        })}
      </div>
    )
  );
};

export default DisplayCourses;

function Arrow(props) {
  const disabled = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      style={{
        width: "1.4rem",
        cursor: "pointer",
      }}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}
