// import { IconButton } from "@material-ui/core";
import {
    Modal,
    Radio,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import Label from "components/InfoLabel";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardContent, CardMedia, IconButton } from "@mui/material";

import { FormControl as Input, Button, FormLabel } from "react-bootstrap";

import { ImImages } from "react-icons/im";
import { RiDeleteBin5Line, RiCloseCircleFill, RiArrowGoBackLine } from "react-icons/ri";

// import CloseIcon from '@material-ui/icons/Close';
import { STORAGE_URL } from "variables";
import { removeHTML } from "utils/filters";
import { Get } from "utils/request";
import DefaultButton from "components/DefaultButton";
import moment from "moment";
import { BsArrowRight } from "react-icons/bs";

const ModalCourses = props => {
    const [course, SetCourse] = useState('');
    const [groups, SetGroups] = useState([]);
    const [groupSelect, SetGroup] = useState('');


    const [loading, SetLoading] = useState(false);


    console.log(props)

    const GetData = async () => {
        if (course == '') {
            return
        }
        SetLoading(true);
        let response = await Get(`courses-main/groups?filter=1&courseId=${course}`);
        SetLoading(false);

        if (response?.status === true) {
            SetGroups(response?.groups);
        }
    }

    useEffect(() => {
        GetData()
    }, [course]);

    return (
        <Modal
            open={props.open}
            onClose={() => {
                props.setModal(false);
            }}
            style={{ overflow: 'scroll' }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    bgcolor: "background.paper",
                    borderRadius: "0.6rem",
                    p: 4,
                    margin:3
                }}
            >
                <Typography variant="h4" sx={{ fontSize: 22, fontWeight: 'bold', textTransform: 'uppercase', color: "black" }}>
                    Selecione o curso/turma que deseja vincular neste aluno
                </Typography>
                <IconButton style={{position:'absolute',top:20,right:20}} onClick={()=>{
                    props.setModal(false)
                }}>
                            <CloseIcon />
                        </IconButton>
                <FormLabel>
                    <br />
                    {/* Função <span style={{ color: "red" }}>*</span> */}
                    <Label
                        label={<span style={{ fontSize: "1.2rem" }}>Curso</span>}
                        required
                        title="A função que o usuário irá desempenhar no sistema."
                    />
                </FormLabel>
                <select
                    required
                    className="form-select"
                    value={course}
                    disabled={loading}
                    onChange={(e) => {
                        SetCourse(e.target.value)
                        SetGroup('')
                    }}
                >
                    <option id={''} value={''}>
                        Selecione um curso
                    </option>
                    {props.courses?.map((value, key) => (
                        <option id={value?.id} key={key} value={value?.id}>
                            {removeHTML(value?.name)}
                        </option>
                    ))}
                </select>

                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Nome	</th>
                                <th>Perído</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading && groups && groups.length != 0 && groups.map((value, index) => (
                                <tr key={index}>

                                    <td>{value.name}</td>
                                    <td><p style={{ marginBottom: 5 }}>{value.name} ({moment(value.use_time_group == false ? moment().format('YYYY-MM-DD HH:mm:ss') : value.sub_start).format('DD/MM/YYYY')} - {value.use_time_group == false ? (value.availability_duration_days == -1 ? '' : moment(moment().format('YYYY-MM-DD HH:mm:ss')).add('days', value.availability_duration_days).format('DD/MM/YYYY')) : moment(value.sub_end).format('DD/MM/YYYY')}) {<Tooltip
                                        title={'Ver Turma'}
                                    >
                                        <a target="_blank" href={`/groups/${value.id}/view`}><BsArrowRight /></a></Tooltip>} </p></td>

                                    <td>
                                        <Radio
                                            style={{
                                                color: groupSelect==value?.id ? "#66bb6a" : ""
                                            }}
                                            value={value?.id}
                                            onChange={e => SetGroup(e.target.value)}
                                            checked={value?.id==groupSelect}
                                        />


                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="flex align-center jcc w100">
                        <p hidden={(groups && groups.length != 0)}>Nenhuma turma encontrada</p>
                    </div>
                </div>

                <div className="w100 inline-flex jcsb mt-2">
        <DefaultButton
          bg="secondary"
          text="Voltar"
          icon={<RiArrowGoBackLine />}
          onClick={()=>{
            props.setModal(false);
          }}
        />
        <DefaultButton
          bg="confirm"
          text="Salvar vinculo"
          disabled={groupSelect=='' || course==''}
          onClick={() => {
            let data = {
             ...groups.filter((item)=>item.id==groupSelect)[0],
             course_id: course,
             course_name: props.courses.filter((item)=>item.id==course)[0].name
            };

            props.onConfirm(data);
          }}
          loading={loading}
        />
      </div>

                {/* <Formbody course_id={course_id} /> */}
            </Box>
        </Modal>
    );
}

export default ModalCourses;