import {
  CircularProgress,
  IconButton,
  Slider,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./index.module.css";

import Controls from "./Controls";
import PlayingOverlay from "./PlayingOverlay";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SkipButton from "./SkipButton";
import { Clamp } from "utils/filters";
import MobileControls from "./MobileControls";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { STORAGE_URL } from "variables";

const Video = forwardRef((props, ref) => {
  const {
    boldTitle,
    title,
    src = null,
    thumbnail = null,
    srcList = [],
    next = "",
    prev = "",
    hidden,
    containerProps,
    startTime = 0,
    autoPlay = false,
    annotations = false,
    onPrev = () => {},
    onNext = () => {},
    onTimeUpdate = () => {},
    onAnnotation = () => {},
    ...other
  } = props;

  var ua = window.navigator.userAgent;
var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
var webkit = !!ua.match(/WebKit/i);
var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

  const [endTime, SetEndTime] = useState(0);
  const [currTime, SetCurrTime] = useState(0);
  const [playing, SetPlaying] = useState(false);
  const [fullscreen, SetFullscreen] = useState(false);
  const [contentLoaded, SetContentLoaded] = useState(false);
  const [hideThumbnail, SetHideThumbnail] = useState(false);

  const player = useRef();
  const container = useRef();
  const timer = useRef();

  useImperativeHandle(ref, () => ({
    ChangeTime: ChangeCurrentTime,
    paused: player?.current?.paused,
    currentTime: player?.current?.currentTime,
  }));

  const ChangeCurrentTime = (timestamp) =>
    (player.current.currentTime = Clamp(timestamp, 0, endTime));

  const ErrorHandling = (e) => {
    switch (e.target.error.code) {
      //MEDIA_ELEMENT_ERROR
      case 4:
        SetSrc(null);
        break;
    }
  };

  const SetSrc = (value) => {
    // if (value === null || value === undefined || value === "")
    //   player?.current && player?.current?.removeAttribute("src");
    // else player?.current && (player.current.src = value);
    // try {
    //   player.current.currentTime = startTime;
    // } catch (err) {
    //   alert(err)
    // }
  };

  useEffect(() => {
    //Checks if the source is valid and then execute the video action
    if (
      player?.current === undefined ||
      src === undefined ||
      src === null ||
      src === ""
    )
      return;
    if (playing) {
      const playPromise = player.current.play();
      if (playPromise !== undefined) {
        playPromise.catch((err) => SetPlaying(false));
      }
    } else player.current.pause();
  }, [playing]);

  useEffect(() => {
    if (fullscreen) {
      if (container?.current.requestFullscreen)
        container.current
          .requestFullscreen()
          .catch((err) => Promise.resolve(err));
      else if (container?.current.msRequestFullscreen)
        container.current
          .msRequestFullscreen()
          .catch((err) => Promise.resolve(err));
      else if (container?.current.mozRequestFullScreen)
        container.current
          .mozRequestFullScreen()
          .catch((err) => Promise.resolve(err));
      else if (container?.current.webkitRequestFullScreen)
        container.current
          .webkitRequestFullScreen()
          .catch((err) => Promise.resolve(err));
    } else {
      if (document.exitFullscreen)
        document.exitFullscreen().catch((err) => Promise.resolve(err));
      else if (document.msExitFullscreen)
        document.msExitFullScreen().catch((err) => Promise.resolve(err));
      else if (document.mozExitFullscreen)
        document.mozExitFullScreen().catch((err) => Promise.resolve(err));
      else if (document.webkitExitFullscreen)
        document.webkitExitFullScreen().catch((err) => Promise.resolve(err));
    }
  }, [fullscreen]);

  useEffect(() => {
    console.log("sadfkoasdkfosdakfoasdifweq", src);
    console.log('content to false 1')
    SetContentLoaded(false)
    if (src?.includes("courses_videos_trailers")) {
      // path
      console.log("path");
      SetSrc(src);
      console.log(src);
    } else {
      console.log("base64");
      // base64
      SetSrc(src);
    }
    SetHideThumbnail(false);
  }, [src]);

  useEffect(() => contentLoaded && SetPlaying(autoPlay), [contentLoaded]);

  useEffect(() => {
    //Timer to sinalize time update to a parent component
    timer.current = setInterval(
      onTimeUpdate(player?.current?.currentTime),
      1000
    );
    return () => clearInterval(timer.current);
  });
  console.log(contentLoaded,player?.current?.currentTime, endTime)

  useEffect(()=>{
    console.log('startTime',startTime)
    if(!Number.isNaN(startTime)){
      player.current.currentTime = startTime;
    }

  },[startTime])
  
  return (
    <div
      className={styles.container}
      hidden={hidden}
      ref={container}
      {...containerProps}
    >
      <PlayingOverlay playing={playing} />

      {!hideThumbnail && !thumbnail?.includes("null") && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            SetPlaying(!playing);
            SetHideThumbnail(true);
          }}
        >
          {thumbnail !== null &&
            thumbnail !== undefined &&
            thumbnail !== "" && (
              <img
                src={thumbnail}
                alt="capa do vídeo"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  zIndex: 8,
                }}
              />
            )}
          <PlayCircleOutlineIcon
            style={{
              fontSize: "8rem",
              color: "white",
              zIndex: 9,
              position: "absolute",
              webkitFilter: "drop-shadow(0px 0px 8px #222)",
              filter: "drop-shadow(0px 0px 8px #222)",
            }}
          />
        </div>
      )}
      <video
      src={src}
      ref={player}
      controls={iOSSafari}
        className={styles.player}
        onMouseUp={() =>
          !navigator?.userAgentData?.mobile && SetPlaying(!playing)
        }
        onDoubleClick={() =>
          !navigator?.userAgentData?.mobile && SetFullscreen(!fullscreen)
        }
        onPlay={()=>{
          
          SetHideThumbnail(true);
        }}
        onLoad={(e)=>{
    console.log('content to true 1')

          SetContentLoaded(true)
        }}
        
        onTimeUpdate={(e) => {SetCurrTime(e.target.currentTime)}}
        onLoadedData={() => {
    console.log('content to true 2')

          SetContentLoaded(true)
          console.log(startTime)
          if(!iOSSafari && !Number.isNaN(startTime)){
            player.current.currentTime = startTime;
          }

      // SetSrc(STORAGE_URL + src);

        }}
        onLoadStart={()=>{
    console.log('content to false 2')

          SetContentLoaded(false)
        }}
        onSuspend={() => {SetContentLoaded(false);console.log('content to false 3')}}
        onError={ErrorHandling}
        autoPlay={autoPlay}
        {...other}
      />
      {next && (
        <SkipButton currentTime={currTime} endTime={endTime} onClick={onNext} />
      )}
      <Controls
        ref={{
          player: player,
          container: container,
        }}
        safari={iOSSafari}
        src={src}
        setPlaying={playing}
        prev={prev}
        next={next}
        onPrev={onPrev}
        onNext={onNext}
        endTime={endTime}

        onEndTime={(value) => SetEndTime(value)}
        onPlayingChange={(value) => SetPlaying(value)}
        hidden={src === "" || src === undefined || src === null}
        onAnnotation={onAnnotation}
        boldTitle={boldTitle}
        title={title}
        contentLoaded={contentLoaded}
        annotations={annotations}
      />
      <MobileControls
        safari={iOSSafari}

        playing={playing}
        onPlayingToggle={() => SetPlaying(!playing)}
        currentTime={player?.current?.currentTime}
        endTime={endTime}
        prev={prev}
        next={next}
        onPrev={onPrev}
        onNext={onNext}
      />
      <div className={styles.loading}>
        <CircularProgress
          hidden={
            player?.current?.readyState >= 3 ||
            player?.current?.currentTime == endTime ||
            !src || 
            contentLoaded==true
          }
          style={{
            color: "white",
          }}
        />
        <div
          className="flex fdcolumn align-center"
          hidden={src !== null || player?.current?.readyState === 4}
        >
          <ErrorOutlineIcon color="error" />
          <Typography
            style={{
              color: "white",
            }}
          >
            Erro ao carregar vídeo
          </Typography>
        </div>
      </div>
    </div>
  );
});

export default Video;
