import Video from "components/Video";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import { Get, Post } from "utils/request";
import { STORAGE_URL, STORAGE_URL_PROTECTED } from "variables";
import logo from "assets/images/rt/icon_transparent.png";
import styles from "./index.module.css";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import { useDispatch, useSelector } from "react-redux";
import { Show } from "actions/SnackbarActions";
import { Document, Page,pdfjs } from 'react-pdf';
import './index.module.css';
import DefaultButton from "components/DefaultButton";
import { MdDownload } from "react-icons/md";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
let completedOnce = false;

const next_prev_button_style = {
  color: "white",
  fontSize: 50,
  padding: "0.4rem",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "rgba(0,0,0,0.5)",
  borderRadius: "0.5rem",
  zIndex: 100,
  opacity: 0.7,
  cursor: "pointer",
  "&:hover": {
    opacity: 1,
  },
};

const VideoContainer = forwardRef((props, ref) => {
  const {
    boldTitle,
    title,
    thumbnail = null,
    path = "",
    type = "",
    prev = "",
    next = "",
    startTime,
    autoPlay = false,
    onAnnotation = () => {},
    course_data,
    refetch,
    fileIsLoading,
    modules,
    ...others
  } = props;

  const videoRef = useRef();
  const [mouseEnterPlayer, SetMouseEnterPlayer] = useState(false);
  const [pages_pdf,setPagesPdf]=useState([]);
  const history = useHistory();
  const { course_id, file_id } = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const token = useSelector(store => store.AppReducer.token);

  const { isFetching, data } = useQuery(
    "log",
    () => {
      setTimeout(() => {
        const form = new FormData();
        form.append("file_id", file_id);
        form.append("video_time", videoRef?.current?.currentTime);
        if(videoRef?.current?.currentTime==undefined){
          return;
        }
        return Post(`lessons/files/log`, form);
      }, 10000);
    },
    {
      refetchInterval: 10000,
      refetchOnWindowFocus: false,
      enabled: type == "video",
    }
  );

  useImperativeHandle(ref, () => ({
    videoRef: videoRef,
  }));

  useEffect(() => {
    if (data?.status === true && data?.userfile?.completed && !completedOnce) {
      queryClient.refetchQueries(["course", course_id]);
      completedOnce = true;
    }
  }, [data]);

  useEffect(()=>{
    console.log('iiciou')
    document.addEventListener('contextmenu', event => event.preventDefault());

  },[])

  // useEffect(() => {
  //   console.log('anterior', prev);
  //   console.log('proximo', next);
  // }, [next, prev]);

  //setting completed flag to false because the file changed, so it may call the courses info again
  useEffect(() => (completedOnce = false), [file_id]);
  // console.log(type)

  const MarkCompleted = async () => {
    console.log(course_data);
    const file_id = course_data?.fileData?.file?.id;

    const form = new FormData();
    form.append("file_id", file_id);
    form.append("completed", true);

    let data = await Post(`lessons/files/mark_completed`, form);

    if (data.status) {
      refetch();
    } else {
      dispatch(
        Show({
          show: true,
          severity: "error",
          message: data.message || "Erro ao marcar aula como completa",
        })
      );
    }
  };
  console.log(path,type)

  return (
    <div
      className="preview-video"
      {...others}
      style={{
        width: "100%",
        height: type !== "video" ? "80%" : "auto",
        position: "relative",
      }}
      // onMouseEnter={() => SetMouseEnterPlayer(true)}
      // onMouseLeave={() => SetMouseEnterPlayer(false)}
    >
      {modules?.length === 0 || modules === undefined && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              height: "80%",
              color: "#999",
              fontSize: "1.2rem",
            }}
          >
            Este curso ainda não possui aulas cadastradas
          </div>
        )}

      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          zIndex: 100,
          backgroundColor: "#f5f5f5",
          opacity: fileIsLoading ? 1 : 0,
          visibility: fileIsLoading ? "visible" : "hidden",
          transition: "opacity 0.2s ease-in-out, visibility 0.2s ease-in-out",
        }}
      >
        <img
          src={logo}
          width="100"
          height="100"
          className="infiniteRotateAnim"
        />
        <span>Carregando...</span>
      </div>
      {/* {prev !== undefined && (
          <SkipPreviousIcon
            sx={{
              ...next_prev_button_style,
              left: "1rem",
              opacity: mouseEnterPlayer ? 1 : 0,
              transition: "opacity 0.2s ease-in-out",
            }}
            onClick={() => {
              // history.push(`/courses/${course_id}/${prev?.id}`);
              console.log("prev", prev);
            }}
          />
        )}
        {next !== undefined && (
          <SkipNextIcon
            sx={{
              ...next_prev_button_style,
              right: "1rem",
              opacity: mouseEnterPlayer ? 1 : 0,
              transition: "opacity 0.2s ease-in-out",
            }}
            onClick={() => {
              // history.push(`/courses/${course_id}/${next?.id}`);
              console.log("next". next);
            }}
          />
        )} */}
      {path && type === "video" && (
        <Video
          src={path && (STORAGE_URL_PROTECTED  + path+'?code='+token)}
          hidden={type != "video"}
          next={next}
          prev={prev}
          ref={videoRef}
          // boldTitle={boldTitle}
          title={title}
          // sx={{ aspectRatio: "16/9", height: "100%" }}
          sx={{ height: "100%" }}
          annotations
          onPrev={() => {
            history.push(`/courses/${course_id}/${prev?.id}`);
            console.log("prev");
          }}
          onNext={() => {
            MarkCompleted().then(() => {
              history.push(`/courses/${course_id}/${next?.id}`);
            });
            console.log("next");
          }}
          onAnnotation={onAnnotation}
          startTime={startTime}
          autoPlay={autoPlay}
          thumbnail={thumbnail}
        />
      )}

      {path && type == "document" && (
        (course_id=='7abb6b4f-c2bf-4ef3-ab19-901d8e6b4c3a' ?
          <Viewer path={path} />
        // <PdfRender path={path} setPagesPdf={setPagesPdf} pages_pdf={pages_pdf} />
        :
        <>
        <div className="d-none d-md-flex" style={{width:'100%',height:'100%'}}>
        <iframe
        type="application/pdf"
          // style={{ aspectRatio: "16/9", width: "100%" }}
          style={{ width: "100%",height:'100%' }}
          src={path && STORAGE_URL + path}
          frameborder="0"
        ></iframe>
        </div>
        <div className="d-flex d-md-none" style={{width:'100%',height:'100%',justifyContent:'center',alignItems:'center'}}>
        <DefaultButton
                onClick={()=>{
                  document.getElementById('link_file').click()
                }}
                style={{fontSize:20}}
                icon={<MdDownload/>}
                                bg="info"
                                text={"Baixar arquivo"}
            />
        </div>
                <a id="link_file" target="_blank" href={path && STORAGE_URL + path}></a>
        </>
        
        )

      )}

    </div>
  );
});

const Viewer = React.memo(({path})=>{

  console.log(new Array(269).fill(0))

  return(
    <div style={{overflow:'scroll',height:'100%'}}>
    {new Array(269).fill(0).map((item,id)=>(
      <div key={id}  style={{padding:10}}>
        <img style={{width:'100%',objectFit:'contain'}} src={STORAGE_URL+'images_pdf/image-'+((id+1).toString().length==1?('00'+(id+1)):((id+1).toString().length==2?('0'+(id+1).toString()):(id+1).toString()))+'.png'} />
      </div>
    ))}
    </div>
  )
})


const PdfRender = React.memo(({ path, pages_pdf, setPagesPdf }) => (
  <div style={{overflow:'scroll',height:'100%',padding:20}}>

  <Document
  loading={() => (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div className="spinner-border text-primary" role="status">
  </div></div>)}
  onLoadSuccess={(e) => {
      console.log(e._pdfInfo.numPages);
      let pages_pdf_ = [];
      if(pages_pdf.length==0){
          for (let i = 0; i < e._pdfInfo.numPages; i++) {
            pages_pdf_.push(1);
          }
          setPagesPdf(pages_pdf_)
          console.log('update')
          console.log(path && STORAGE_URL + path)
      }
     
  }}
  file={{
      url: path && STORAGE_URL + path
  }}
>

  {pages_pdf.map((page, id2) => {

      return (
          <Page key={id2} pageIndex={id2} className="pdf_page" />
      );
  }
  )}


</Document>
</div>
), (prevProps, nextProps) => {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
});

export default VideoContainer;
