import { Box, Icon } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { STORAGE_URL } from "variables";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useHistory, useParams } from "react-router";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Post } from "utils/request";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";

const LessonItem = ({ lesson, small_screen, setHideSideBar, refetch }) => {
  const history = useHistory();
  const { course_id, file_id } = useParams();
  const dispatch = useDispatch();

  const [has_video, setHasVideo] = useState(false);
  const [video_duration, setVideoDuration] = useState(0);
  const [this_lesson, setThisLesson] = useState(null);
  const [lesson_complete, setLessonComplete] = useState(false);
  const [loading_complete, setLoadingComplete] = useState(false);

  const MarkCompleted = async () => {
    const file_id = lesson?.files.videos[0]?.id;
    const completed = !lesson_complete;

    if (file_id === undefined) {
      dispatch(
        Show({
          show: true,
          severity: "error",
          message: "Você precisa assistir a aula para marcar como completa",
        })
      );
      return;
    }

    const form = new FormData();
    form.append("file_id", file_id);
    form.append("completed", completed);

    setLoadingComplete(true);
    let data = await Post(`lessons/files/mark_completed`, form);
    setLoadingComplete(false);

    if (data.status) {
      setLessonComplete(completed);
      refetch();
    } else {
      dispatch(
        Show({
          show: true,
          severity: "error",
          message: data.message || "Erro ao marcar aula como completa",
        })
      );
    }
  };

  useEffect(() => {
    if (lesson?.files.videos.length > 0) {
      setHasVideo(true);

      // convert miliseconds to seconds, minutes and hours and format it
      const duration = new Date(lesson?.files.videos[0]?.duration * 1000)
        .toISOString()
        .substr(11, 8);
      setVideoDuration(duration);

      setLessonComplete(lesson?.files?.videos[0]?.userfile?.completed);
    }
  }, [lesson]);

  useEffect(() => {
    if (
      file_id === lesson.files.videos[0]?.id ||
      file_id === lesson.files.documents[0]?.id
    ) {
      setThisLesson(true);
    } else {
      setThisLesson(false);
    }
  }, [file_id]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem",
        cursor: "pointer",
        position: "relative",
        backgroundColor: this_lesson ? "cyan" : "#f5f5f5",
        "&:hover": {
          backgroundColor: "#e0e0e0",
        },
      }}
      onClick={() => {
        history.push(
          lesson.files.videos[0] !== undefined
            ? `/courses/${course_id}/${lesson.files.videos[0]?.id}`
            : `/courses/${course_id}/${lesson.files.documents[0]?.id}`
        );
        small_screen && setHideSideBar(true);
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        {has_video && (
          <span
            style={{
              position: "absolute",
              bottom: "-0.2rem",
              left: "0",
              fontSize: "0.7rem",
              padding: "0 0.3rem 0 0",
              borderRadius: "0 0.3rem 0 0",
              color: "black",
              backgroundColor: "white",
            }}
          >
            {video_duration}
          </span>
        )}
        {/* thumbnail */}
        {has_video ? (
          <img
            // src={}
            src={
              lesson?.cover_image && !lesson?.cover_image?.includes("null")
                ? STORAGE_URL + lesson?.cover_image
                : STORAGE_URL + lesson?.files.videos[0].thumbnail_image
            }
            alt="Video thumbnail"
            style={{
              objectFit: "cover",
              width: "5rem",
              height: "3.4rem",
              borderRadius: "0.5rem",
            }}
          />
        ) : (
          <div
            style={{
              width: "5rem",
              height: "3.4rem",
              borderRadius: "0.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#c9f0e5",
            }}
          >
            <PictureAsPdfIcon />
          </div>
        )}
        {has_video && (
          <Icon
            component={
              loading_complete
                ? AutorenewIcon
                : lesson_complete
                ? CheckBoxIcon
                : CheckBoxOutlineBlankIcon
            }
            sx={{
              position: "absolute",
              right: "-0.2rem",
              bottom: "-0.1rem",
              color: lesson_complete ? "#6ad424" : "#c7c7c7",
              backgroundColor: "white",
              borderRadius: loading_complete ? "50%" : "0.3rem 0 0 0",
            }}
            className={loading_complete ? "infiniteRotateAnim" : ""}
            onClick={(e) => {
              e.stopPropagation();
              MarkCompleted();
            }}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          justifyContent: "center",
        }}
      >
        <span
          style={{
            fontSize: "1.1rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {lesson?.title}
        </span>
        {/* {lesson?.description && (
          <div
            style={{
              fontSize: "0.9rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {lesson?.description}
          </div>
        )} */}
      </div>
    </Box>
  );
};

export default LessonItem;
