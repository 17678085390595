import { Typography } from "@mui/material";
import { STORAGE_URL } from "variables";
import default_user_image from 'assets/images/default.png';
import StarPurple500OutlinedIcon from "@mui/icons-material/StarPurple500Outlined";

const TestimonyCard = ({ testimony }) => {
  const checkIfisLocalImage = (image_url) => {
    if (testimony.image !== null && testimony.image !== undefined) {
      image_url = testimony.image;
    }

    if (image_url === null || image_url === undefined || image_url === 'undefined') {
      return default_user_image;
    }

    if (image_url.includes("data:image")) {
      return image_url;
    } else {
      return `${STORAGE_URL}${image_url}`;
    }
  };

  return (
    <div
      style={{
        flexGrow: 1,
        maxWidth: "26rem",
        gap: "1.2rem",
        display: "flex",
        flexDirection: "column",
        background: "#f3f6f6",
        padding: "2rem",
        borderRadius: "1rem",
        boxShadow: "0 0 0 0.1rem rgb(43 43 43 / 10%)",
        position: "relative",
        overflow: "hidden",
        margin: "0.2rem 0",
      }}
    >
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <div>
          <img
            style={{
              width: "4rem",
              height: "4rem",
              borderRadius: "50%",
              backgroundPosition: "center",
              backgroundSize: "cover",
              boxShadow: "0 0 0 0.1rem rgba(105, 59, 159, 1)",
              objectFit: "cover",
            }}
            src={checkIfisLocalImage(testimony.image_url)}
            alt=""
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            style={{
              fontSize: "1.4rem",
              fontFamily: "var(--Freight)",
              fontWeight: "bold",
            }}
          >
            {testimony.name}
          </span>
        </div>
      </div>

      <div
        style={{
          color: "#e9c46a",
          position: "absolute",
          right: 0,
          top: 0,
          background: "#e4e4e4",
          padding: "0.2rem 0.4rem",
          borderRadius: "0 0 0 1rem",
        }}
      >
        <StarPurple500OutlinedIcon />
        <StarPurple500OutlinedIcon />
        <StarPurple500OutlinedIcon />
        <StarPurple500OutlinedIcon />
        <StarPurple500OutlinedIcon />
      </div>

      <Typography
        variant="subtitle1"
        component="div"
        sx={{
          color: "black",
          height: "8.8rem",
          whiteSpace: "wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        "{testimony.text}"
      </Typography>
    </div>
  );
};

export default TestimonyCard;
