import Video from "components/Video";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import { Get, Post } from "utils/request";
import { STORAGE_URL } from "variables";

import styles from "./index.module.css";

let completedOnce = false;

const VideoContainer = forwardRef((props, ref) => {
  const {
    boldTitle,
    title,
    thumbnail = null,
    path = "",
    type = "",
    prev = "",
    next = "",
    startTime,
    autoPlay = false,
    onAnnotation = () => {},
    ...others
  } = props;

  const videoRef = useRef();

  const history = useHistory();
  const { course_id, file_id } = useParams();
  const queryClient = useQueryClient();
  const { isFetching, data } = useQuery(
    "log",
    () => {
      setTimeout(() => {
        const form = new FormData();
        form.append("file_id", file_id);
        form.append("video_time", videoRef?.current?.currentTime);
        if(videoRef?.current?.currentTime==undefined){
          return;
        }
        return Post(`lessons/files/log`, form);
      }, 10000);
    },
    {
      refetchInterval: 10000,
      refetchOnWindowFocus: false,
      enabled: type == "video",
    }
  );

  useImperativeHandle(ref, () => ({
    videoRef: videoRef,
  }));

  useEffect(() => {
    if (data?.status === true && data?.userfile?.completed && !completedOnce) {
      queryClient.refetchQueries(["course", course_id]);
      completedOnce = true;
    }
  }, [data]);

  //setting completed flag to false because the file changed, so it may call the courses info again
  useEffect(() => (completedOnce = false), [file_id]);
  // console.log(type)
  return (
    <div className="preview-video" {...others}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {path && type === "video" && (
        <Video
          src={path && STORAGE_URL + path}
          hidden={type != "video"}
          next={next}
          prev={prev}
          ref={videoRef}
          boldTitle={boldTitle}
          title={title}
          // sx={{ aspectRatio: "16/9", height: "100%" }}
          sx={{ height: "100%" }}
          annotations
          onPrev={() => {
            history.push(`/courses/${course_id}/${prev?.id}`);
            console.log("prev");
          }}
          onNext={() => {
            history.push(`/courses/${course_id}/${next?.id}`);
            console.log("next");
          }}
          onAnnotation={onAnnotation}
          startTime={startTime}
          autoPlay={autoPlay}
          thumbnail={thumbnail}
        />
      )}

      {path && type == "document" && (
          <iframe
            // style={{ aspectRatio: "16/9", width: "100%" }}
            style={{ width: "100%", height: '100%' }}
            src={path && STORAGE_URL + path}
          ></iframe>
          )}
          {/* <Video
                    src={path && (STORAGE_URL + path)}
                    hidden={type != "video"}
                    next={next}
                    prev={prev}
                    ref={videoRef}
                    boldTitle={boldTitle}
                    title={title}
                    autoPlay
                    annotations
                    onPrev={() => {
                        history.push(`/courses/${course_id}/${prev?.id}`);
                        console.log("prev");
                    }}
                    onNext={() => {
                        history.push(`/courses/${course_id}/${next?.id}`)
                        console.log("next");
                    }}
                    onAnnotation={onAnnotation}
                    startTime={startTime}
                /> */}
    </div>
  );
});

export default VideoContainer;
