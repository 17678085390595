import SelectAutocomplete from "components/Inputs/SelectAutocomplete";
import {
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Get, Post } from "utils/request";
import { useHistory, useParams } from "react-router";
import { useEffect, useState } from "react";
import DefaultButton from "components/DefaultButton";
import { RiArrowGoBackLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { Show } from "actions/SnackbarActions";
import { FormLabel, FormControl as Input } from "react-bootstrap";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const CourseGroupsEdit = () => {
  const [groups, SetGroups] = useState([]);
  const [toAddGroups, SetToAddGroups] = useState([]);
  const [loading, SetLoading] = useState(false);

  const { group_id, course_id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [name, SetName] = useState("");
  const [description, SetDescription] = useState("");
  const [subStart, SetSubStart] = useState(Date.now());
  const [subEnd, SetSubEnd] = useState(Date.now());
  const [availabilityDuration, SetAvailabilityDuration] = useState(30);
  const [notify_students, SetNotifyStudents] = useState(false);
  const [blockNotify, SetBlockNotify] = useState(false);
  const [is_student, setIsStudent] = useState(false);
  const [use_time_group, setUseTimeGroup] = useState(false);

  const GetData = async () => {
    let response = await Get(`groups?id=${group_id}`);

    if (response?.status === true) {
      SetName(response?.group?.name);
      SetDescription(response?.group?.description);
      SetSubStart(
        moment(response?.group?.sub_start, "YYYY-MM-DD", true).toDate()
      );
      SetSubEnd(moment(response?.group?.sub_end, "YYYY-MM-DD", true).toDate());
      SetAvailabilityDuration(response?.group?.availability_duration_days);
      SetNotifyStudents(response?.group?.notify_students);
      setIsStudent(response?.group?.is_student);
      setUseTimeGroup(response?.group?.use_time_group);


      SetBlockNotify(response?.group?.notify_students);
    }
  };

  const [popup, SetPopup] = useState(false);
  const [popupMessage, SetPopupMessage] = useState("");

  const Submit = async () => {
    if (!name) {
      SetPopup(true);
      SetPopupMessage("Insira um nome!");
      return;
    }
    let form = new FormData();
    form.append("course_id", course_id);
    form.append("group_id", group_id);
    form.append("name", name);
    form.append("description", description);
    form.append("is_student", is_student);
    form.append("use_time_group", use_time_group);



    form.append("notify_students", blockNotify ? false : notify_students);

    form.append("availability_duration_days", availabilityDuration);
    form.append("sub_start", new Date(subStart).toISOString().split("T")[0]);
    form.append("sub_end", new Date(subEnd).toISOString().split("T")[0]);
    // for (let i = 0; i < toAddGroups?.length; i++) form.append("group_id[]", toAddGroups[i]?.id);

    SetLoading(true);
    let response = await Post("courses-main/groups/updatecoursegroup", form);
    SetLoading(false);

    dispatch(
      Show({
        show: true,
        message: response?.message,
        severity: response?.severity,
      })
    );

    if (response?.status === true)
      history.push({
        pathname: `/courses/view/${course_id}`,
        search: "?t=2",
      });
  };

  useEffect(GetData, []);
  return (
    <div className="card">
      <div className="card-body">
        <Typography variant="h4" gutterBottom>
          Editar turma
        </Typography>
        <Typography gutterBottom>
          Aqui é editado uma turma do curso em questão
        </Typography>
        <br />
        <SweetAlert
          warning
          show={popup}
          title="Atenção!"
          confirmBtnText="Ok"
          onConfirm={() => SetPopup(false)}
        >
          {popupMessage}
        </SweetAlert>
        {/* <SelectAutocomplete
                    items={groups}
                    onSearch={GetData}
                    onChange={groups => SetToAddGroups(groups)}
                    placeholder={"Pesquisar por grupos"}
                    notFoundMessage={"Nenhum grupo encontrado"}
                    zeroItemsMessage={"Nenhum grupo selecionado"}
                    tableBody={[
                        { objLabel: "name", header: "Nome" },
                        { objLabel: "description", header: "Descrição" }
                    ]}
                /> */}
        <FormLabel>
          Nome <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Input
          placeholder="Nome"
          value={name}
          onChange={(e) => SetName(e.target.value)}
          required
        />
        <br />

        <FormLabel>Descrição</FormLabel>
        <Input
          placeholder="Descrição"
          value={description}
          onChange={(e) => SetDescription(e.target.value)}
          as="textarea"
        />
        <br />
        <div className="flex fdcolumn">
          <FormLabel>
            Período de inscrição <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              value={subStart}
              onChange={SetSubStart}
              label={"Inicio das incrições"}
              inputFormat={"dd/MM/yyyy"}
              renderInput={(params) => <TextField {...params} sx={{ mb: 2 }} />}
              // maxDate={subEnd}
            />
            <MobileDatePicker
              value={subEnd}
              onChange={SetSubEnd}
              label={"Fim das incrições"}
              inputFormat={"dd/MM/yyyy"}
              renderInput={(params) => <TextField {...params} />}
              minDate={subStart}
            />
          </LocalizationProvider>
        </div>

        <br />
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={!use_time_group}
                onClick={() => {
                  setUseTimeGroup(false);
                }}
              />
            }
            label="Começar contar tempo do aluno a partir da data de entrada"
          />
          <FormControlLabel
            control={
              <Switch
                checked={use_time_group}
                onClick={() => {
                  setUseTimeGroup(true);
                }}
              />
            }
            label="Utilizar período da turma como tempo de acesso ao curso"
          />
        </FormGroup>
        <hr/>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={notify_students}
                onClick={() => {
                  if (blockNotify) return;
                  SetNotifyStudents(!notify_students);
                }}
              />
            }
            label="Notificar os alunos interessados por e-mail sobre a disponibilidade do curso"
          />
        </FormGroup>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Ao ativar e salvar, o
            sistema enviará um e-mail para todos os alunos que estão
            interessados neste curso, informando que o curso está disponível
            para inscrição.
          </span>
          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "#e6ac39" }} /> Após salvar a turma
            com essa opção ativada, não será possível desativá-la.
          </span>
        </div>
        <hr/>


        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={is_student}
                onClick={() => {
                  setIsStudent(true);
                }}
              />
            }
            label="Turma para alunos"
          />
          <FormControlLabel
            control={
              <Switch
                checked={!is_student}
                onClick={() => {
                  setIsStudent(false);
                }}
              />
            }
            label="Turma para não alunos"
          />
        </FormGroup>
        <hr/>


        {use_time_group==false && <div className="flex fdcolumn">
          <FormLabel>Tempo de disponibilidade</FormLabel>
          <select
            className="form-control"
            value={availabilityDuration}
            onChange={(e) => SetAvailabilityDuration(e.target.value)}
          >
            <option value={30}>1 mês</option>
            <option value={60}>2 meses</option>
            <option value={90}>3 meses</option>
            <option value={180}>6 meses</option>
            <option value={270}>9 meses</option>
            <option value={365}>12 meses</option>
              <option value={730}>24 meses</option>
            <option value={-1}>Indefinido</option>
          </select>
        </div>}
        <br />
        <div className="w100 inline-flex jcsb mt-2">
          <DefaultButton
            bg="secondary"
            text="Voltar"
            icon={<RiArrowGoBackLine />}
            to={`/courses/view/${course_id}`}
            search={"?t=2"}
          />
          <DefaultButton
            bg="confirm"
            text="Editar turma"
            onClick={Submit}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default CourseGroupsEdit;
