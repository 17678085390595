import { Box, Typography, Button, useTheme } from "@mui/material";
import { CircularProgressLabel } from "components/CircularProgressLabel";
import { useCallback } from "react";
import { useIsFetching } from "react-query";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import DownloadIcon from "@mui/icons-material/Download";
import { API_URL } from "variables";
import moment from "moment";
import { removeHTML } from "utils/filters";

const ProgressContainer = (props) => {
  const {
    progress,
    name,
    free,
    usercourse: { certificate = {}, group = null,course_end=null } = {},
    ...others
  } = props;

  const { course_id } = useParams();
  const user = useSelector((store) => store.AppReducer.user);
  const isFetching = useIsFetching(["course", course_id]);
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        gap: "0.6rem",
        width: "100%",
      }}
    >
      <div>
        <CircularProgressLabel
          value={parseInt(progress?.percentage) || 0}
          label={isFetching ? "..." : `${parseInt(progress?.percentage) || 0}%`}
          size={100}
        />

        <Typography variant="h6"
          style={{
            width: '100%',
          }}
        >
          <b>{removeHTML(name)}</b>
        </Typography>
      </div>

      <Typography variant="body2" style={{ fontSize: "1rem", opacity: 0.7 }}>
        <b>Curso encerra em: </b>
        {(course_end === null || free==true)
          ? "Indeterminado"
          : moment(course_end).format("DD/MM/YYYY")}
      </Typography>
      {/* {group === null && (
        <span>
          Logado como Administrador, a data de encerramento será indeterminada.
        </span>
      )} */}
      <Box>
        {progress >= 0 && certificate && (
          <Button
            component={"a"}
            startIcon={<DownloadIcon />}
            href={`${API_URL}certificate/download?certificate_id=${certificate?.id}`}
            target={"_blank"}
            download={"Certificado"}
          >
            Baixar certificado
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ProgressContainer;
