import { Box, Container, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Get } from "utils/request";

import Image from "components/Image";
import Board from "../CoursesUserView/Card";
import emptyState from "assets/images/emptystate2.svg";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import SkeletonCardList from "modules/LoadingPlaceholders/Skeletons/SkeletonCardList";
import Footer from "Home/Footer";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

const MyCourses = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider(
      {
        breakpoints: {
          "(min-width: 0px)": {
            slides: { perView: 1 },
          },
          "(min-width: 940px)": {
            slides: { perView: 2 },
          },
          "(min-width: 1000px)": {
            slides: { perView: 2 },
          },
          "(min-width: 1380px)": {
            slides: { perView: 3 },
          },
          "(min-width: 1700px)": {
            slides: { perView: 4 },
          },
          "(min-width: 2200px)": {
            slides: { perView: 5 },
          },
        },
        slides: {
          origin: "center",
          // perView: 1,
          // spacing: 10,
        },
        loop: true,
        initial: 0,
        slideChanged(slider) {
          setCurrentSlide(slider.track.details.rel);
        },
        created() {
          setLoaded(true);
        },
      },
      [
        (slider) => {
          let timeout;
          let mouseOver = false;
          function clearNextTimeout() {
            clearTimeout(timeout);
          }
          function nextTimeout() {
            clearTimeout(timeout);
            if (mouseOver) return;
            timeout = setTimeout(() => {
              // slider?.next();
            }, 5000);
          }
          slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
              mouseOver = true;
              clearNextTimeout();
            });
            slider.container.addEventListener("mouseout", () => {
              mouseOver = false;
              nextTimeout();
            });
            nextTimeout();
          });
          slider.on("dragStarted", clearNextTimeout);
          slider.on("animationEnded", nextTimeout);
          slider.on("updated", nextTimeout);
        },
      ]
    );
  const [mui_theme, setMuiTheme] = useState({});
  const [courses, setCourses] = useState([]);

  const loginTheme = useSelector(
    (store) => store.StylesReducer.pagesThemes?.mural_cursos
  );
  const userId = useSelector((store) => store.AppReducer.user.id);

  useEffect(() => {
    setMuiTheme(loginTheme?.palette?.data);
  }, [loginTheme]);
  const [isLoading, setLoading] = useState(true);

  // const { data, refetch } = useQuery("my-courses", async () => {
  //   let data = await Get(`user-course/from/user/${userId}`);
  //   setLoading(false);
  //   return data;
  // });

  // const { courses = [] } = data || {};

  const getCourses = async () => {
    let res = await Get(`user-course/get-users-course/${userId}`);
    setLoading(false);

    if (res?.status) {
      console.log(res?.courses);
      setCourses(res?.courses);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);
  useEffect(() => {

  instanceRef?.current?.update();
}, [courses]);

  

  if (isLoading)
    return (
      <Container maxWidth="xl" sx={{ padding: "0", paddingTop: "2rem" }}>
        <SkeletonCardList />
      </Container>
    );
    console.log(instanceRef)

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          backgroundColor: mui_theme.background_color,
          minHeight: "100vh",
          paddingTop: "2rem",
        }}
      >
        <Typography
          variant="h4"
          style={{
            display: "flex",
            gap: "0.2rem",
            alignItems: "center",
            fontWeight: "bold",
            paddingLeft: "2rem",
            color: mui_theme.font_color,
          }}
        >
          Meus cursos
        </Typography>
        <div
          ref={sliderRef}
          className="keen-slider"
          style={{
            overflowY: "visible",
            padding: "1.4rem 0",
          }}
        >
          {courses?.map((course, key) => {
            // if (!course.free) return;

            return (
              <div
                className="keen-slider__slide"
                style={{
                  overflow: "visible",
                }}
                key={key}
              >
                <Board
                  isUserIn={course?.usercourse !== null}
                  // GetData={refetch}
                  fontColor={mui_theme.font_color}
                  {...course}
                />
              </div>
            )
          })}
           {loaded && instanceRef.current && (
                    <>
                      <div
                        style={{
                          backgroundColor: "rgba(255, 255,255 ,0.5)",
                          borderRadius: "50%",
                          padding: "1rem",
                          position: "absolute",
                          display: "flex",
                          justifyContent: "space-between",
                          top: "45%",
                        }}
                      >
                        <Arrow
                          left
                          onClick={(e) =>{
                            try{
                            e.stopPropagation() 
                            instanceRef.current?.prev()
                            }
                            catch(e){
                              console.log(e)
                            }

                          }
                          }
                          disabled={currentSlide === 0}
                        />
                      </div>
                      <div
                        style={{
                          backgroundColor: "rgba(255, 255,255 ,0.5)",
                          borderRadius: "50%",
                          padding: "1rem",
                          position: "absolute",
                          display: "flex",
                          justifyContent: "space-between",
                          top: "45%",
                          right: "0",
                        }}
                      >
                        <Arrow
                          onClick={(e) =>{
                            try{
                              e.stopPropagation() 
                              instanceRef.current?.next()
                              }
                              catch(e){
                                console.log(e)
                              }
                          }
                          }
                          disabled={
                            currentSlide ===
                            instanceRef.current?.track?.details?.slides?.length - 1
                          }
                        />
                      </div>
                    </>
                  )}
        </div>
        {!isLoading && courses?.length == 0 && (
          <Box
            width="100%"
            display="flex"
            flexDirection={"column"}
            alignItems="center"
          >
            <Image
              src={emptyState}
              width={320}
              height={300}
              style={{
                backgroundSize: "cover",
              }}
            />
            <Typography color={mui_theme.font_color} variant="h5" marginTop={'2rem'}>Lista de cursos vazia</Typography>
          </Box>
        )}
      </div>
      <Footer />
    </>
  );
};

export default MyCourses;


function Arrow(props) {
  const disabled = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      style={{
        width: "1.4rem",
        cursor: "pointer",
      }}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}
